:root {
    --main-color: #f45766;
}

.App {
    text-align: center;
}

.btn-primary:active {
    background-color: var(--main-color);
    border-color: var(--main-color);
}

.color-primary {
    color: var(--main-color) !important;
}

.text-success {
    color: var(--main-color) !important;
}

.b-primary {
    background-color: var(--main-color);
    border-color: var(--main-color);
    color: white;
}

.order-method__left-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-color: transparent;
    margin-right: 0;
    font-weight: bold;
    border-color: rgba(67, 41, 163, .2);
    color: var(--main-color);
}

.order-method__right-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-weight: bold;
    color: var(--main-color) !important;
    border-color: rgba(67, 41, 163, .2)
}

.order-method__button__active {
    background-color: var(--main-color);
    color: white !important;
}

.btn:hover {
    border-color: var(--main-color);
    color: white !important;
    background-color: var(--main-color);
}

.navbar-shadow {
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .1), 0 1px 8px 0 rgba(67, 41, 163, .1)
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield; /* Firefox */
}

.b-primary:disabled {
    color: #fff;
    background-color: var(--main-color);
    border-color: var(--main-color);
}

.map {
    height: 300px;
}



.overlay:before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #212529;
    opacity: .7;
    pointer-events: none;
    transition: opacity .3s ease .1s;
}

.overlay {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.bg-header .overlay-color-dark:before {
    background-color: #212529 !important;
}


.section-title {
    padding-top: 100px;
}

.custom-divider {
    border: 0;
    height: 56px;
    background: transparent url(https://spa-console.s3.amazonaws.com/Captcajun/divider.png) no-repeat center center;
}